import { Text, Flex, TooltipText, useTooltip } from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'
import { DeserializedPool } from 'state/types'
import BigNumber from 'bignumber.js'
import Length from 'views/Pools/components/Length'

interface LengthRowProps {
  pool: DeserializedPool
}

const LengthRow: React.FC<LengthRowProps> = ({ pool }) => {
  const { t } = useTranslation()
  const { vaultKey } = pool

  const tooltipContent = vaultKey
    ? t('APY includes compounding, APR doesn’t. This pool’s CAKE is compounded automatically, so we show APY.')
    : t('This pool’s rewards aren’t compounded automatically, so we show APR')

  const { targetRef, tooltip, tooltipVisible } = useTooltip(tooltipContent, { placement: 'bottom-start' })

  return (
    <Flex alignItems="center" justifyContent="space-between">
       <Text bold>LENGTH</Text>
       <Length pool={pool}  />
    </Flex>
  )
}

export default LengthRow
