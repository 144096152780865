import styled from 'styled-components'
import { Text, Flex, useModal, CalculateIcon, Skeleton, FlexProps, Button } from '@pancakeswap/uikit'
import { DeserializedPool } from 'state/types'
import { useTranslation } from 'contexts/Localization'
import { useCurrentBlock } from 'state/block/hooks'
import { getPoolBlockInfo } from 'views/Pools/helpers'

const LengthLabelContainer = styled(Flex)`
  &:hover {
    opacity: 0.5;
  }
`

interface LengthProps extends FlexProps {
  pool: DeserializedPool
}

const Length: React.FC<LengthProps> = ({ pool, ...props }) => {
  const {
    isFinished,
    length,
  } = pool
  const { t } = useTranslation()
  const currentBlock = useCurrentBlock()

  const { shouldShowBlockCountdown, hasPoolStarted } = getPoolBlockInfo(pool, currentBlock)

  const isValidate = true

  return (
    <LengthLabelContainer alignItems="center" justifyContent="flex-start" {...props}>
      {isValidate || isFinished ? (
        <>
          {hasPoolStarted || !shouldShowBlockCountdown ? (
            <>
              
              {!isFinished && (
                <Text fontSize="18px" color="#000000" textAlign="left">
: {length} 
              </Text>
              )}
            </>
          ) : (
            <Text>-</Text>
          )}
        </>
      ) : (
<Text fontSize="18px" color="#ffffff" textAlign="left">
  
        </Text>      )}
    </LengthLabelContainer>
  )
}

export default Length
